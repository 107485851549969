import React from "react";
import "../assets/css/loading.css";

const Loading = () => {
  return (
    <div className="flex items-center justify-center flex-col h-[100vh] w-full">
      <span className="loader"></span>
    </div>
  );
};

export default Loading;
