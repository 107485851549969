import { ErrorMessage, Field, Form, Formik } from "formik";
import { Info } from "lucide-react";
import React, { useState } from "react";
import * as Yup from "yup";
import Button from "./Button";
import { applyNow } from "../api/api";
import { useLocation } from "react-router-dom";

function ApplyNowForm() {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const isSIANotRequired = location?.state?.data === "transportation";
  const baseValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    criminalConvictions: Yup.string().required("This field is required"),
    criminalRecord: Yup.string()
      .oneOf(["Yes", "No"], "Select Yes or No")
      .required("This field is required"),
    criminalConvictionsExplanation: Yup.string().when("criminalConvictions", {
      is: "Yes",
      then: () =>
        Yup.string().required(
          "Explanation is required when there is a criminal conviction "
        ),
      otherwise: () => Yup.string().notRequired(),
    }),
    criminalExplanation: Yup.string().when("criminalRecord", {
      is: "Yes",
      then: () =>
        Yup.string().required(
          "Explanation is required when there is a criminal record"
        ),
      otherwise: () => Yup.string().notRequired(),
    }),
    civilOrder: Yup.string()
      .oneOf(["Yes", "No"], "Select Yes or No")
      .required("This field is required"),
    civilExplanation: Yup.string().when("civilOrder", {
      is: "Yes",
      then: () =>
        Yup.string().required(
          "Explanation is required when there is a civil order"
        ),
      otherwise: () => Yup.string().notRequired(),
    }),
    ccjOrBankruptcy: Yup.string()
      .oneOf(["Yes", "No"], "Select Yes or No")
      .required("This field is required"),
    ccjExplanation: Yup.string().when("ccjOrBankruptcy", {
      is: "Yes",
      then: () =>
        Yup.string().required("Explanation is required for CCJ or Bankruptcy"),
      otherwise: () => Yup.string().notRequired(),
    }),
    allegedOffences: Yup.string()
      .oneOf(["Yes", "No"], "Select Yes or No")
      .required("This field is required"),
    allegedExplanation: Yup.string().when("allegedOffences", {
      is: "Yes",
      then: () =>
        Yup.string().required("Explanation is required for alleged offences"),
      otherwise: () => Yup.string().notRequired(),
    }),
    eligibilityUK: Yup.string().required("This field is required"),
    immigrationControl: Yup.string()
      .oneOf(["Yes", "No"], "Select Yes or No")
      .required("This field is required"),
    immigrationExplanation: Yup.string().when("immigrationControl", {
      is: "Yes",
      then: () =>
        Yup.string().required(
          "Explanation is required when immigration control is Yes"
        ),
      otherwise: () => Yup.string().notRequired(),
    }),
    fiveYearHistory: Yup.string().required("This field is required"),
    undeclaredEarnings: Yup.string().required("This field is required"),
    drivingLicence: Yup.string(),
    shiftAvailability: Yup.array().min(1, "At least one day must be selected"),
    workOnHoliday: Yup.string()
      .oneOf(["Yes", "No"], "Select Yes or No")
      .required("This field is required"),
    loneWorking: Yup.string()
      .oneOf(["Yes", "No"], "Select Yes or No")
      .required("This field is required"),
    position: Yup.string().required("This field is required"),
    applicationDate: Yup.date().required("This field is required").nullable(),
    fullName: Yup.string().required("Full name is required"),
    dob: Yup.date().required("Date of birth is required").nullable(),
    SIAlicenceExpiry: Yup.date()
      .required("SIA licence expiry is required")
      .nullable(),
    SIAlicenceNumber: Yup.string().required("SIA licence number is required"),
    nationalNumber: Yup.string().required("National number is required"),
    address2optional: Yup.string(),
    address1: Yup.string().required("Address is required"),
    emailAddress: Yup.string()
      .email("Invalid email format")
      .required("Email address is required"),
    contactNumber: Yup.string().required("Contact number is required"),
    recentEmployerName: Yup.string().required(
      "Recent employer name is required"
    ),
    city: Yup.string().required("City is required"),
    country: Yup.string().required("Country is required"),
    postCode: Yup.string().required("Postcode is required"),
    positionHeld: Yup.string().required("Position held is required"),
    startDate: Yup.date().required("Start date is required").nullable(),
    endDate: Yup.date().required("End date is required").nullable(),
    responsibilities: Yup.string().required("Responsibilities are required"),
    leavingReason: Yup.string().required("Leaving reason is required"),
    nextEmployerNameOptional: Yup.string(),
    nextPositionHeldOptional: Yup.string(),
    nextStartDateOptional: Yup.date().nullable(),
    nextEndDateOptional: Yup.date().nullable(),
    nextResponsibilitiesOptional: Yup.string(),
    nextLeavingReasonOptional: Yup.string(),
    schoolCollegeUniversity: Yup.string().required(
      "School/College/University is required"
    ),
    courseName: Yup.string().required("Course name is required"),
    additionalCourses: Yup.string(),
    yearObtained: Yup.string().required("Year obtained is required"),
    qualification: Yup.string().required("Qualification is required"),
    institutionOptional: Yup.string(),
    dateCompletedOptional: Yup.date().nullable(),
    referenceEmail1: Yup.string()
      .email("Invalid email format")
      .required("Reference email is required"),
    referenceContactNumber1: Yup.string().required(
      "Reference contact number is required"
    ),
    referenceCompany1: Yup.string().required("Reference company is required"),
    referencePosition1: Yup.string().required("Reference position is required"),
    referenceName1: Yup.string().required("Reference name is required"),
    referenceEmail2: Yup.string().email("Invalid email format"),
    referenceContactNumber2: Yup.string(),
    referenceCompany2: Yup.string(),
    referencePosition2: Yup.string(),
    referenceName2: Yup.string(),
    validUkDrivingLicence: Yup.string(),
    drivingLicenceNumberOptional: Yup.string(),
    drivingLicenceExpiryOptional: Yup.date().nullable(),
    endorsementsExplanationOptional: Yup.string(),
    endorsementsOptional: Yup.string(),
    ownVehicle: Yup.string()
      .oneOf(["Yes", "No"], "Select Yes or No")
      .required("This field is required"),
    vehicleMakeModelOptional: Yup.string(),
    vehicleRegistrationNumberOptional: Yup.string(),
    vehicleCapacityOptional: Yup.string(),
    proofOfId: Yup.string().required("Proof of ID is required"),
    proofOfAddress1: Yup.string().required("Proof of address is required"),
    proofOfAddress2: Yup.string(),
    proofOfNationalInsurance: Yup.string().required(
      "Proof of National Insurance is required"
    ),
    proofOfSIABadge: Yup.string().required("Proof of SIA badge is required"),
    passportSizePhoto: Yup.string().required("Passport size photo is required"),
    additionalInformationOptional: Yup.string(),
    signatureDate: Yup.date().required("Signature date is required").nullable(),
    signature: Yup.string().required("Signature is required"),
  });

  const validationSchema = isSIANotRequired
    ? baseValidationSchema.shape({
        SIAlicenceExpiry: Yup.date(),

        SIAlicenceNumber: Yup.string(),
        proofOfSIABadge: Yup.string(),
        validUkDrivingLicence: Yup.string()
          .oneOf(["Yes", "No", "International"], "Select Yes or No")
          .required("This field is required"),
        drivingLicence: Yup.string()
          .oneOf(["Yes", "No"], "Select Yes or No")
          .required("This field is required"),
      })
    : baseValidationSchema;

  const initialValues = {
    email: "",
    criminalConvictions: "",
    criminalConvictionsExplanation: "",
    criminalRecord: "",
    criminalExplanation: "",
    civilOrder: "",
    civilExplanation: "",
    ccjOrBankruptcy: "",
    ccjExplanation: "",
    allegedOffences: "",
    allegedExplanation: "",
    eligibilityUK: "",
    immigrationControl: "",
    immigrationExplanation: "",
    fiveYearHistory: "",
    undeclaredEarnings: "",
    drivingLicence: "",
    shiftAvailability: [],
    workOnHoliday: "",
    loneWorking: "",
    position: "",
    applicationDate: "",
    fullName: "",
    dob: "",
    SIAlicenceExpiry: "",
    SIAlicenceNumber: "",
    nationalNumber: "",
    address2optional: "",
    address1: "",
    emailAddress: "",
    contactNumber: "",
    recentEmployerName: "",
    city: "",
    country: "",
    postCode: "",
    positionHeld: "",
    startDate: "",
    endDate: "",
    responsibilities: "",
    leavingReason: "",
    nextEmployerNameOptional: "",
    nextPositionHeldOptional: "",
    nextStartDateOptional: "",
    nextEndDateOptional: "",
    nextResponsibilitiesOptional: "",
    nextLeavingReasonOptional: "",
    schoolCollegeUniversity: "",
    courseName: "",
    additionalCourses: "",
    yearObtained: "",
    qualification: "",
    institutionOptional: "",
    dateCompletedOptional: "",
    referenceEmail1: "",
    referenceContactNumber1: "",
    referenceCompany1: "",
    referencePosition1: "",
    referenceName1: "",
    referenceEmail2: "",
    referenceContactNumber2: "",
    referenceCompany2: "",
    referencePosition2: "",
    referenceName2: "",
    validUkDrivingLicence: "",
    drivingLicenceNumberOptional: "",
    drivingLicenceExpiryOptional: "",
    endorsementsExplanationOptional: "",
    endorsementsOptional: "",
    ownVehicle: "",
    vehicleMakeModelOptional: "",
    vehicleRegistrationNumberOptional: "",
    vehicleCapacityOptional: "",
    proofOfId: "",
    proofOfAddress1: "",
    proofOfAddress2: "",
    proofOfNationalInsurance: "",
    proofOfSIABadge: "",
    passportSizePhoto: "",
    additionalInformationOptional: "",
    signatureDate: "",
    signature: "",
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(initialValues, { resetForm }) => {
          const formData = new FormData();

          formData.append("email", initialValues.email);
          formData.append("fullName", initialValues.fullName);
          formData.append("dob", initialValues.dob);
          formData.append("contactNumber", initialValues.contactNumber);
          formData.append("address1", initialValues.address1);
          formData.append("address2optional", initialValues.address2optional);
          formData.append("city", initialValues.city);
          formData.append("country", initialValues.country);
          formData.append("postCode", initialValues.postCode);
          formData.append("emailAddress", initialValues.emailAddress);

          formData.append("position", initialValues.position);
          formData.append("applicationDate", initialValues.applicationDate);

          formData.append("SIAlicenceExpiry", initialValues.SIAlicenceExpiry);
          formData.append("SIAlicenceNumber", initialValues.SIAlicenceNumber);

          formData.append("nationalNumber", initialValues.nationalNumber);
          formData.append("site", location?.state?.data);
          formData.append(
            "criminalConvictions",
            initialValues.criminalConvictions
          );
          formData.append(
            "criminalConvictionsExplanation",
            initialValues.criminalConvictionsExplanation
          );
          formData.append("criminalRecord", initialValues.criminalRecord);
          formData.append(
            "criminalExplanation",
            initialValues.criminalExplanation
          );
          formData.append("civilOrder", initialValues.civilOrder);
          formData.append("civilExplanation", initialValues.civilExplanation);
          formData.append("ccjOrBankruptcy", initialValues.ccjOrBankruptcy);
          formData.append("ccjExplanation", initialValues.ccjExplanation);
          formData.append("allegedOffences", initialValues.allegedOffences);
          formData.append(
            "allegedExplanation",
            initialValues.allegedExplanation
          );

          formData.append("eligibilityUK", initialValues.eligibilityUK);
          formData.append(
            "immigrationControl",
            initialValues.immigrationControl
          );
          formData.append(
            "immigrationExplanation",
            initialValues.immigrationExplanation
          );
          formData.append("fiveYearHistory", initialValues.fiveYearHistory);
          formData.append(
            "undeclaredEarnings",
            initialValues.undeclaredEarnings
          );

          formData.append(
            "validUkDrivingLicence",
            initialValues.validUkDrivingLicence
          );
          formData.append("drivingLicence", initialValues.drivingLicence);
          formData.append(
            "drivingLicenceNumberOptional",
            initialValues.drivingLicenceNumberOptional
          );
          formData.append(
            "drivingLicenceExpiryOptional",
            initialValues.drivingLicenceExpiryOptional
          );
          formData.append(
            "endorsementsOptional",
            initialValues.endorsementsOptional
          );
          formData.append(
            "endorsementsExplanationOptional",
            initialValues.endorsementsExplanationOptional
          );
          formData.append("ownVehicle", initialValues.ownVehicle);
          formData.append(
            "vehicleMakeModelOptional",
            initialValues.vehicleMakeModelOptional
          );
          formData.append(
            "vehicleRegistrationNumberOptional",
            initialValues.vehicleRegistrationNumberOptional
          );
          formData.append(
            "vehicleCapacityOptional",
            initialValues.vehicleCapacityOptional
          );

          formData.append(
            "recentEmployerName",
            initialValues.recentEmployerName
          );
          formData.append("positionHeld", initialValues.positionHeld);
          formData.append("startDate", initialValues.startDate);
          formData.append("endDate", initialValues.endDate);
          formData.append("responsibilities", initialValues.responsibilities);
          formData.append("leavingReason", initialValues.leavingReason);

          formData.append(
            "nextEmployerNameOptional",
            initialValues.nextEmployerNameOptional
          );
          formData.append(
            "nextPositionHeldOptional",
            initialValues.nextPositionHeldOptional
          );
          formData.append(
            "nextStartDateOptional",
            initialValues.nextStartDateOptional
          );
          formData.append(
            "nextEndDateOptional",
            initialValues.nextEndDateOptional
          );
          formData.append(
            "nextResponsibilitiesOptional",
            initialValues.nextResponsibilitiesOptional
          );
          formData.append(
            "nextLeavingReasonOptional",
            initialValues.nextLeavingReasonOptional
          );

          formData.append(
            "schoolCollegeUniversity",
            initialValues.schoolCollegeUniversity
          );
          formData.append("courseName", initialValues.courseName);
          formData.append("additionalCourses", initialValues.additionalCourses);
          formData.append("yearObtained", initialValues.yearObtained);
          formData.append("qualification", initialValues.qualification);
          formData.append(
            "institutionOptional",
            initialValues.institutionOptional
          );
          formData.append(
            "dateCompletedOptional",
            initialValues.dateCompletedOptional
          );

          formData.append("referenceName1", initialValues.referenceName1);
          formData.append(
            "referencePosition1",
            initialValues.referencePosition1
          );
          formData.append("referenceCompany1", initialValues.referenceCompany1);
          formData.append("referenceEmail1", initialValues.referenceEmail1);
          formData.append(
            "referenceContactNumber1",
            initialValues.referenceContactNumber1
          );

          formData.append("referenceName2", initialValues.referenceName2);
          formData.append(
            "referencePosition2",
            initialValues.referencePosition2
          );
          formData.append("referenceCompany2", initialValues.referenceCompany2);
          formData.append("referenceEmail2", initialValues.referenceEmail2);
          formData.append(
            "referenceContactNumber2",
            initialValues.referenceContactNumber2
          );

          formData.append(
            "shiftAvailability",
            JSON.stringify(initialValues.shiftAvailability)
          );
          formData.append("workOnHoliday", initialValues.workOnHoliday);
          formData.append("loneWorking", initialValues.loneWorking);

          if (initialValues.proofOfId)
            formData.append("proofOfId", initialValues.proofOfId);
          if (initialValues.proofOfAddress1)
            formData.append("proofOfAddress1", initialValues.proofOfAddress1);
          if (initialValues.proofOfAddress2)
            formData.append("proofOfAddress2", initialValues.proofOfAddress2);
          if (initialValues.proofOfNationalInsurance)
            formData.append(
              "proofOfNationalInsurance",
              initialValues.proofOfNationalInsurance
            );
          if (initialValues.proofOfSIABadge)
            formData.append("proofOfSIABadge", initialValues.proofOfSIABadge);
          if (initialValues.passportSizePhoto)
            formData.append(
              "passportSizePhoto",
              initialValues.passportSizePhoto
            );

          formData.append(
            "additionalInformationOptional",
            initialValues.additionalInformationOptional
          );
          formData.append("signatureDate", initialValues.signatureDate);
          formData.append("signature", initialValues.signature);

          applyNow(formData, setIsLoading).then(() => {
            resetForm();
          });
        }}
      >
        {(formik) => (
          <Form>
            <div className="mt-[90px] pt-4 bg-gray-100 pb-5">
              <div className="w-full px-4  container mx-auto">
                <h1 className="section-title !text-primary py-4 w-full text-center">
                  Job Application Form
                  <Info
                    onClick={() =>
                      document.getElementById("my_modal_2").showModal()
                    }
                    className=" inline-block cursor-pointer"
                    size={20}
                  />
                </h1>
                <p className="section-text pb-4  w-full md:w-[90%] lg:w-[70%] mx-auto text-center">
                  MH7 Services Ltd complies with the requirements of the BS 7858
                  and as such all applicants must complete a screening and
                  licensing process.
                </p>
                <div className="bg-white shadow-md rounded-xl h-auto w-full md:w-[90%] xl:w-[80%] p-4 mt-6 mx-auto">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="form-group">
                      <label className="form-label">Email*</label>
                      <Field
                        name="email"
                        type="email"
                        placeholder="Your email"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">Full Name *</label>
                      <Field
                        name="fullName"
                        type="text"
                        placeholder="Enter name"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="fullName"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">Date of Birth *</label>
                      <Field
                        name="dob"
                        type="date"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="dob"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        National Insurance Number *
                      </label>
                      <Field
                        name="nationalNumber"
                        type="text"
                        placeholder="Your National Number"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="nationalNumber"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group md:col-span-12">
                      <label>
                        Do you have any unspent criminal convictions or
                        prosecutions pending (including driving offences)? *
                      </label>
                      <div className="flex  flex-col space-x-4 mt-2">
                        <div className="flex items-center">
                          <label className="form-label">
                            <Field
                              type="radio"
                              name="criminalConvictions"
                              value="Yes"
                            />
                            Yes
                          </label>
                          <label className="form-label !ms-3">
                            <Field
                              type="radio"
                              name="criminalConvictions"
                              value="No"
                            />
                            No
                          </label>
                          <p className="form-text">(If Yes, Please Explain)</p>
                        </div>
                        <ErrorMessage
                          name="criminalConvictions"
                          component="div"
                          className="text-red-500"
                        />
                      </div>

                      <Field
                        name="criminalConvictionsExplanation"
                        as="textarea"
                        placeholder="Your answer"
                        className="input input-bordered min-h-[120px] w-full mt-2"
                      />
                      <ErrorMessage
                        name="criminalConvictionsExplanation"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group md:col-span-12">
                      <label>
                        Are there any alleged offences outstanding against you?
                        *
                      </label>
                      <div className="flex flex-col space-x-4 mt-2">
                        <div className="flex items-center">
                          <label className="form-label">
                            <Field
                              type="radio"
                              name="allegedOffences"
                              value="Yes"
                            />
                            Yes
                          </label>
                          <label className="form-label !ms-3">
                            <Field
                              type="radio"
                              name="allegedOffences"
                              value="No"
                            />
                            No
                          </label>
                          <p className="form-text">(If Yes, Please Explain)</p>
                        </div>
                        <ErrorMessage
                          name="allegedOffences"
                          component="div"
                          className="text-red-500"
                        />
                      </div>

                      <Field
                        name="allegedExplanation"
                        as="textarea"
                        placeholder="Your answer"
                        className="input input-bordered min-h-[120px] w-full mt-2"
                      />
                      <ErrorMessage
                        name="allegedExplanation"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group md:col-span-12">
                      <label>
                        Have you ever been fined, cautioned, sentenced to
                        imprisonment, or placed on probation for a criminal act
                        (subject to the Rehabilitation of Offenders Act)?*
                      </label>
                      <div className="flex flex-col space-x-4 mt-2">
                        <div className="flex items-center">
                          <label className="form-label">
                            <Field
                              type="radio"
                              name="criminalRecord"
                              value="Yes"
                            />
                            Yes
                          </label>
                          <label className="form-label !ms-3">
                            <Field
                              type="radio"
                              name="criminalRecord"
                              value="No"
                            />
                            No
                          </label>
                          <p className="form-text">(If Yes, Please Explain)</p>
                        </div>
                        <ErrorMessage
                          name="criminalRecord"
                          component="div"
                          className="text-red-500"
                        />
                      </div>

                      <Field
                        name="criminalExplanation"
                        as="textarea"
                        placeholder="Your answer"
                        className="input input-bordered min-h-[120px] w-full mt-2"
                      />
                      <ErrorMessage
                        name="criminalExplanation"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group md:col-span-12">
                      <label>
                        Has any order been made against you by a Civil or
                        Military Court or Public Authority?*
                      </label>
                      <div className="flex flex-col space-x-4 mt-2">
                        <div className="flex items-center">
                          <label className="form-label">
                            <Field type="radio" name="civilOrder" value="Yes" />
                            Yes
                          </label>
                          <label className="form-label !ms-3">
                            <Field type="radio" name="civilOrder" value="No" />
                            No
                          </label>
                          <p className="form-text">(If Yes, Please Explain)</p>
                        </div>
                        <ErrorMessage
                          name="civilOrder"
                          component="div"
                          className="text-red-500"
                        />
                      </div>

                      <Field
                        name="civilExplanation"
                        as="textarea"
                        placeholder="Your answer"
                        className="input input-bordered min-h-[120px] w-full mt-2"
                      />
                      <ErrorMessage
                        name="civilExplanation"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group md:col-span-12">
                      <label>
                        Do you have any current active County Court Judgement
                        (CCJ) against you, or have you been the subject of an
                        IVA (Individual Voluntary Arrangement) or been declared
                        bankrupt in the last six years?*
                      </label>
                      <div className="flex flex-col space-x-4 mt-2">
                        <div className="flex items-center">
                          <label className="form-label">
                            <Field
                              type="radio"
                              name="ccjOrBankruptcy"
                              value="Yes"
                            />
                            Yes
                          </label>
                          <label className="form-label !ms-3">
                            <Field
                              type="radio"
                              name="ccjOrBankruptcy"
                              value="No"
                            />
                            No
                          </label>
                          <p className="form-text">(If Yes, Please Explain)</p>
                        </div>
                        <ErrorMessage
                          name="ccjOrBankruptcy"
                          component="div"
                          className="text-red-500"
                        />
                      </div>

                      <Field
                        name="ccjExplanation"
                        as="textarea"
                        placeholder="Your answer"
                        className="input input-bordered min-h-[120px] w-full mt-2"
                      />
                      <ErrorMessage
                        name="ccjExplanation"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group md:col-span-12">
                      <label>Are you subject to immigration control? *</label>
                      <div className="flex flex-col space-x-4 mt-2">
                        <div className="flex items-center">
                          <label className="form-label">
                            <Field
                              type="radio"
                              name="immigrationControl"
                              value="Yes"
                            />
                            Yes
                          </label>
                          <label className="form-label !ms-3">
                            <Field
                              type="radio"
                              name="immigrationControl"
                              value="No"
                            />
                            No
                          </label>
                          <p className="form-text">(If Yes, Please Explain)</p>
                        </div>
                        <ErrorMessage
                          name="immigrationControl"
                          component="div"
                          className="text-red-500"
                        />
                      </div>

                      <Field
                        name="immigrationExplanation"
                        as="textarea"
                        placeholder="Your answer"
                        className="input input-bordered min-h-[120px] w-full mt-2"
                      />
                      <ErrorMessage
                        name="immigrationExplanation"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group !col-span-12">
                      <label>
                        Do you have eligibility to work in the UK? *
                      </label>
                      <div className="flex space-x-4 mt-2">
                        <label>
                          <Field
                            type="radio"
                            name="eligibilityUK"
                            value="Yes"
                          />
                          Yes
                        </label>
                        <label>
                          <Field type="radio" name="eligibilityUK" value="No" />
                          No
                        </label>
                      </div>
                      <ErrorMessage
                        name="eligibilityUK"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group !col-span-12">
                      <label>
                        Do you have a minimum of five-year checkable history?
                        With NO gaps. *
                      </label>
                      <div className="flex space-x-4 mt-2">
                        <label>
                          <Field
                            type="radio"
                            name="fiveYearHistory"
                            value="Yes"
                          />
                          Yes
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="fiveYearHistory"
                            value="No"
                          />
                          No
                        </label>
                      </div>
                      <ErrorMessage
                        name="fiveYearHistory"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group !col-span-12">
                      <label>
                        Have you ever undertaken paid work and NOT declared Your
                        earnings to the HMRC? *
                      </label>
                      <div className="flex space-x-4 mt-2">
                        <label>
                          <Field
                            type="radio"
                            name="undeclaredEarnings"
                            value="Yes"
                          />
                          Yes
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="undeclaredEarnings"
                            value="No"
                          />
                          No
                        </label>
                      </div>
                      <ErrorMessage
                        name="undeclaredEarnings"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group !col-span-12">
                      <label>
                        Do you hold a UK Driving licence and have access to Your
                        own transport? *
                      </label>
                      <div className="flex space-x-4 mt-2">
                        <label>
                          <Field
                            type="radio"
                            name="drivingLicence"
                            value="Yes"
                          />
                          Yes
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="drivingLicence"
                            value="No"
                          />
                          No
                        </label>
                      </div>
                      <ErrorMessage
                        name="drivingLicence"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group !col-span-12">
                      <label>
                        Are you willing and able to work bank or public
                        holidays? *
                      </label>
                      <div className="flex space-x-4 mt-2">
                        <label>
                          <Field
                            type="radio"
                            name="workOnHoliday"
                            value="Yes"
                          />
                          Yes
                        </label>
                        <label>
                          <Field type="radio" name="workOnHoliday" value="No" />
                          No
                        </label>
                      </div>
                      <ErrorMessage
                        name="workOnHoliday"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group !col-span-12">
                      <label>Are you comfortable lone working? *</label>
                      <div className="flex space-x-4 mt-2">
                        <label>
                          <Field type="radio" name="loneWorking" value="Yes" />
                          Yes
                        </label>
                        <label>
                          <Field type="radio" name="loneWorking" value="No" />
                          No
                        </label>
                      </div>
                      <ErrorMessage
                        name="loneWorking"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group md:col-span-12">
                      <label>
                        Are you willing and able to work shift patterns which
                        will include days, nights and weekend shifts? *
                      </label>
                      <div className="flex flex-col mt-2 ">
                        <label>
                          <Field
                            type="checkbox"
                            name="shiftAvailability"
                            value="Monday"
                          />
                          Monday
                        </label>
                        <label>
                          <Field
                            type="checkbox"
                            name="shiftAvailability"
                            value="Tuesday"
                          />
                          Tuesday
                        </label>
                        <label>
                          <Field
                            type="checkbox"
                            name="shiftAvailability"
                            value="Wednesday"
                          />
                          Wednesday
                        </label>
                        <label>
                          <Field
                            type="checkbox"
                            name="shiftAvailability"
                            value="Thursday"
                          />
                          Thursday
                        </label>
                        <label>
                          <Field
                            type="checkbox"
                            name="shiftAvailability"
                            value="Friday"
                          />
                          Friday
                        </label>
                        <label>
                          <Field
                            type="checkbox"
                            name="shiftAvailability"
                            value="Saturday"
                          />
                          Saturday
                        </label>
                        <label>
                          <Field
                            type="checkbox"
                            name="shiftAvailability"
                            value="Sunday"
                          />
                          Sunday
                        </label>
                      </div>
                      <ErrorMessage
                        name="shiftAvailability"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group">
                      <label>Position Applied For *</label>
                      <Field
                        name="position"
                        type="text"
                        placeholder="Your position"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="position"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group">
                      <label>Date of Application *</label>
                      <Field
                        name="applicationDate"
                        type="date"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="applicationDate"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    {!isSIANotRequired && (
                      <>
                        <div className="form-group">
                          <label>SIA Licence Number *</label>
                          <Field
                            name="SIAlicenceNumber"
                            type="text"
                            placeholder="Your SIA licence number"
                            className="input input-bordered w-full"
                          />
                          <ErrorMessage
                            name="SIAlicenceNumber"
                            component="div"
                            className="text-red-500"
                          />
                        </div>
                        <div className="form-group">
                          <label>SIA Licence Expiry Date *</label>
                          <Field
                            name="SIAlicenceExpiry"
                            type="date"
                            className="input input-bordered w-full"
                          />
                          <ErrorMessage
                            name="SIAlicenceExpiry"
                            component="div"
                            className="text-red-500"
                          />
                        </div>
                      </>
                    )}
                    <div className="form-group">
                      <label>Contact Number *</label>
                      <Field
                        name="contactNumber"
                        type="text"
                        placeholder="Your contact"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="contactNumber"
                        component="div"
                        className="text-red-500"
                      />
                    </div>{" "}
                    <div className="form-group">
                      <label>Email Address *</label>
                      <Field
                        name="emailAddress"
                        type="text"
                        placeholder="Your email address"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="emailAddress"
                        component="div"
                        className="text-red-500"
                      />
                    </div>{" "}
                    <div className="form-group">
                      <label>Address Line 1 *</label>
                      <Field
                        name="address1"
                        type="text"
                        placeholder="Your address line"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="address1"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group">
                      <label>Address Line 2</label>
                      <Field
                        name="address2optional"
                        type="text"
                        placeholder="Your address line"
                        className="input input-bordered w-full"
                      />
                    </div>
                    <div className="form-group">
                      <label>City *</label>
                      <Field
                        name="city"
                        type="text"
                        placeholder="Your city"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="city"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group">
                      <label>County *</label>
                      <Field
                        name="country"
                        type="text"
                        placeholder="Your country"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="country"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group">
                      <label>Postcode *</label>
                      <Field
                        name="postCode"
                        type="text"
                        placeholder="Your post code"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="postCode"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Employer Name{" "}
                        <span className="form-text !ms-0">
                          (Most Recent Employment)
                        </span>{" "}
                        *
                      </label>
                      <Field
                        name="recentEmployerName"
                        type="text"
                        placeholder="Your recent employer"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="recentEmployerName"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group">
                      <label>Position Held *</label>
                      <Field
                        name="positionHeld"
                        type="text"
                        placeholder="Your position held"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="positionHeld"
                        component="div"
                        className="text-red-500"
                      />
                    </div>{" "}
                    <div className="form-group">
                      <label>Start Date *</label>
                      <Field
                        name="startDate"
                        type="date"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="startDate"
                        component="div"
                        className="text-red-500"
                      />
                    </div>{" "}
                    <div className="form-group">
                      <label>End Date *</label>
                      <Field
                        name="endDate"
                        type="date"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="endDate"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group">
                      <label>Responsibilities *</label>
                      <Field
                        name="responsibilities"
                        type="text"
                        placeholder="Your responsibilities"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="responsibilities"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group">
                      <label>Reason for Leaving *</label>
                      <Field
                        name="leavingReason"
                        type="text"
                        placeholder="Your leaving reason"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="leavingReason"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group">
                      <label>Employer Name (Next Employment)</label>
                      <Field
                        name="nextEmployerNameOptional"
                        type="text"
                        placeholder="Your next employer name"
                        className="input input-bordered w-full"
                      />
                    </div>
                    <div className="form-group">
                      <label>Position Held</label>
                      <Field
                        name="nextPositionHeldOptional"
                        type="text"
                        placeholder="Your next position held"
                        className="input input-bordered w-full"
                      />
                    </div>
                    <div className="form-group">
                      <label>Start Date</label>
                      <Field
                        name="nextStartDateOptional"
                        type="date"
                        className="input input-bordered w-full"
                      />
                    </div>
                    <div className="form-group">
                      <label>End Date</label>
                      <Field
                        name="nextEndDateOptional"
                        type="date"
                        className="input input-bordered w-full"
                      />
                    </div>
                    <div className="form-group">
                      <label>Responsibilities</label>
                      <Field
                        name="nextResponsibilitiesOptional"
                        type="text"
                        placeholder="Your responsibilities"
                        className="input input-bordered w-full"
                      />
                    </div>
                    <div className="form-group">
                      <label>Reason for Leaving</label>
                      <Field
                        name="nextLeavingReasonOptional"
                        type="text"
                        className="input input-bordered w-full"
                        placeholder="Your reason for leaving"
                      />
                    </div>
                    <div className="form-group">
                      <label>School/College/University *</label>
                      <Field
                        name="schoolCollegeUniversity"
                        type="text"
                        placeholder="Your school/college/university"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="schoolCollegeUniversity"
                        component="div"
                        className="text-red-500"
                      />
                    </div>{" "}
                    <div className="form-group">
                      <label>Qualification *</label>
                      <Field
                        name="qualification"
                        type="text"
                        placeholder="Your qualification"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="qualification"
                        component="div"
                        className="text-red-500"
                      />
                    </div>{" "}
                    <div className="form-group">
                      <label>Year Obtained *</label>
                      <Field
                        name="yearObtained"
                        type="text"
                        placeholder="Enter  year obtained"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="yearObtained"
                        component="div"
                        className="text-red-500"
                      />
                    </div>{" "}
                    <div className="form-group">
                      <label>
                        Additional Training/Courses{" "}
                        <span className="form-text !ms-0">
                          (related to security and driving)
                        </span>{" "}
                        *
                      </label>
                      <Field
                        name="additionalCourses"
                        type="text"
                        placeholder="Your additional courses"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="additionalCourses"
                        component="div"
                        className="text-red-500"
                      />
                    </div>{" "}
                    <div className="form-group">
                      <label>Course Name *</label>
                      <Field
                        name="courseName"
                        type="text"
                        placeholder="Enter course name"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="courseName"
                        component="div"
                        className="text-red-500"
                      />
                    </div>{" "}
                    <div className="form-group">
                      <label>Institution</label>
                      <Field
                        name="institutionOptional"
                        type="text"
                        placeholder="Your institution"
                        className="input input-bordered w-full"
                      />
                    </div>{" "}
                    <div className="form-group">
                      <label>Date Completed</label>
                      <Field
                        name="dateCompletedOptional"
                        type="date"
                        placeholder="Enter  complition date"
                        className="input input-bordered w-full"
                      />
                    </div>
                    <div className="form-group">
                      <label>Reference Name 1 *</label>
                      <Field
                        name="referenceName1"
                        type="text"
                        placeholder="Enter reference name"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="referenceName1"
                        component="div"
                        className="text-red-500"
                      />
                    </div>{" "}
                    <div className="form-group">
                      <label>Reference Position 1 *</label>
                      <Field
                        name="referencePosition1"
                        type="text"
                        placeholder="Enter reference position"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="referencePosition1"
                        component="div"
                        className="text-red-500"
                      />
                    </div>{" "}
                    <div className="form-group">
                      <label>Reference Company 1 *</label>
                      <Field
                        name="referenceCompany1"
                        type="text"
                        placeholder="Enter reference company"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="referenceCompany1"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group">
                      <label>Reference Contact Number 1 *</label>
                      <Field
                        name="referenceContactNumber1"
                        type="text"
                        placeholder="Enter reference contact"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="referenceContactNumber1"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group">
                      <label>Reference Email 1 *</label>
                      <Field
                        name="referenceEmail1"
                        type="text"
                        placeholder="Enter reference email"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="referenceEmail1"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group">
                      <label>Reference Name 2 *</label>
                      <Field
                        name="referenceName2"
                        type="text"
                        placeholder="Enter reference name"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="referenceName2"
                        component="div"
                        className="text-red-500"
                      />
                    </div>{" "}
                    <div className="form-group">
                      <label>Reference Position 2 *</label>
                      <Field
                        name="referencePosition2"
                        type="text"
                        placeholder="Enter reference position"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="referencePosition2"
                        component="div"
                        className="text-red-500"
                      />
                    </div>{" "}
                    <div className="form-group">
                      <label>Reference Company 2 *</label>
                      <Field
                        name="referenceCompany2"
                        type="text"
                        placeholder="Enter reference company"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="referenceCompany2"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group">
                      <label>Reference Contact Number 2 *</label>
                      <Field
                        name="referenceContactNumber2"
                        type="text"
                        placeholder="Enter reference contact"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="referenceContactNumber2"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group">
                      <label>Reference Email 2 *</label>
                      <Field
                        name="referenceEmail2"
                        type="text"
                        placeholder="Enter reference email"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="referenceEmail2"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group !col-span-12">
                      <label>Do you hold a valid UK Driving Licence? *</label>
                      <div className="flex space-x-4 mt-2">
                        <label>
                          <Field
                            type="radio"
                            name="validUkDrivingLicence"
                            value="Yes"
                          />
                          Yes
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="validUkDrivingLicence"
                            value="No"
                          />
                          No
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="validUkDrivingLicence"
                            value="International"
                          />
                          International
                        </label>
                      </div>
                      <ErrorMessage
                        name="validUkDrivingLicence"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group">
                      <label>Driving Licence Number</label>
                      <Field
                        name="drivingLicenceNumberOptional"
                        type="text"
                        className="input input-bordered w-full"
                        placeholder="Your driving licence"
                      />
                    </div>
                    <div className="form-group">
                      <label>Driving Licence Expiry Date</label>
                      <Field
                        name="drivingLicenceExpiryOptional"
                        type="date"
                        className="input input-bordered w-full"
                      />
                    </div>
                    <div className="form-group md:col-span-12 ">
                      <label>Any endorsements?</label>
                      <div className="flex flex-col space-x-4 mt-2">
                        <div className="flex items-center">
                          <label className="form-label">
                            <Field
                              type="radio"
                              name="endorsementsOptional"
                              value="Yes"
                            />
                            Yes
                          </label>
                          <label className="form-label !ms-3">
                            <Field
                              type="radio"
                              name="endorsements"
                              value="No"
                            />
                            No
                          </label>
                          <p className="form-text">(If Yes, Please Explain)</p>
                        </div>
                      </div>

                      <Field
                        name="endorsementsExplanationOptional"
                        as="textarea"
                        placeholder="Your answer"
                        className="input input-bordered min-h-[120px] w-full mt-2"
                      />
                    </div>
                    <div className="form-group">
                      <label>Do you own a vehicle? *</label>
                      <div className="flex space-x-4 mt-2">
                        <label>
                          <Field type="radio" name="ownVehicle" value="Yes" />
                          Yes
                        </label>
                        <label>
                          <Field type="radio" name="ownVehicle" value="No" />
                          No
                        </label>
                      </div>
                      <ErrorMessage
                        name="ownVehicle"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group">
                      <label>Make and Model of Vehicle</label>
                      <Field
                        name="vehicleMakeModelOptional"
                        type="text"
                        placeholder="Enter make and model"
                        className="input input-bordered w-full"
                      />
                    </div>
                    <div className="form-group">
                      <label>Vehicle Registration Number</label>
                      <Field
                        name="vehicleRegistrationNumberOptional"
                        placeholder={"Your vehicle registration number"}
                        type="text"
                        className="input input-bordered w-full"
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Vehicle Capacity{" "}
                        <span className="form-text !ms-0">
                          (number of passengers it can accommodate)
                        </span>
                      </label>
                      <Field
                        name="vehicleCapacityOptional"
                        type="text"
                        placeholder={"Enter vehicle capacity"}
                        className="input input-bordered w-full"
                      />
                    </div>
                    <div className="form-group">
                      <label>Is the vehicle insured for business use?</label>
                      <div className="flex space-x-4 mt-2">
                        <label>
                          <Field
                            type="radio"
                            name="vehicleInsuredOptional"
                            value="Yes"
                          />
                          Yes
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="vehicleInsuredOptional"
                            value="No"
                          />
                          No
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>
                        Proof of ID - Right to Work{" "}
                        <span className="form-text !ms-0">
                          (As Mentioned in Above List)
                        </span>{" "}
                        *
                      </label>
                      <div>
                        <input
                          name="proofOfId"
                          type="file"
                          className="input input-bordered w-full"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            formik.setFieldValue("proofOfId", file);
                          }}
                        />
                        <ErrorMessage
                          name="proofOfId"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label>
                        Proof of Address # 1 -{" "}
                        <span className="form-text !ms-0">
                          (As Mentioned in Above List)
                        </span>{" "}
                        *
                      </label>
                      <input
                        name="proofOfAddress1"
                        type="file"
                        className="input input-bordered w-full"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          formik.setFieldValue("proofOfAddress1", file);
                        }}
                      />
                      <ErrorMessage
                        name="proofOfAddress1"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Proof of Address # 2 -{" "}
                        <span className="form-text !ms-0">
                          (As Mentioned in Above List)
                        </span>{" "}
                        *
                      </label>
                      <input
                        name="proofOfAddress2"
                        type="file"
                        className="input input-bordered w-full"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          formik.setFieldValue("proofOfAddress2", file);
                        }}
                      />
                      <ErrorMessage
                        name="proofOfAddress2"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group md:col-span-12">
                      <label>
                        Proof of National Insurance (one of the below)
                        <ul>
                          <li className="form-text !ms-0 !my-0">
                            {" "}
                            P45 – printed copy only, or
                          </li>
                          <li className="form-text !ms-0 !block !my-1">
                            P60 printed copy only, or
                          </li>
                          <li className="form-text !ms-0 !my-0">
                            Letter from H.M. Revenue and Customs or department
                            of Work and Pensions Payslip – printed copy showing
                            previous employers company name (must be within 6
                            months)
                          </li>
                        </ul>
                      </label>
                      <input
                        name="proofOfNationalInsurance"
                        type="file"
                        className="input input-bordered w-full"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          formik.setFieldValue(
                            "proofOfNationalInsurance",
                            file
                          );
                        }}
                      />
                      <ErrorMessage
                        name="proofOfNationalInsurance"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    {!isSIANotRequired && (
                      <div className="form-group">
                        <label>Photo of SIA Badge *</label>
                        <input
                          name="proofOfSIABadge"
                          type="file"
                          className="input input-bordered w-full"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            formik.setFieldValue("proofOfSIABadge", file);
                          }}
                        />
                        {console.log(formik.values)}
                        <ErrorMessage
                          name="proofOfSIABadge"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    )}
                    <div className="form-group">
                      <label>Headshot - Passport Size Photo *</label>
                      <input
                        name="passportSizePhoto"
                        type="file"
                        className="input input-bordered w-full"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          formik.setFieldValue("passportSizePhoto", file);
                        }}
                      />
                      <ErrorMessage
                        name="passportSizePhoto"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Additional Information{" "}
                        <span className="form-text !ms-0">
                          (supporting Your application)
                        </span>
                      </label>
                      <Field
                        name="additionalInformationOptional"
                        type="text"
                        placeholder="Enter  additional information"
                        className="input input-bordered w-full"
                      />
                    </div>
                    <div className="form-group">
                      <label>Signature *</label>
                      <Field
                        name="signature"
                        type="text"
                        className="input input-bordered w-full"
                        placeholder="Your signature"
                      />
                      <ErrorMessage
                        name="signature"
                        component="div"
                        className="text-red-500"
                      />
                    </div>{" "}
                    <div className="form-group">
                      <label>Date of Signature *</label>
                      <Field
                        name="signatureDate"
                        type="date"
                        className="input input-bordered w-full"
                      />
                      <ErrorMessage
                        name="signatureDate"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                  <div className="my-4">
                    <Button
                      type="submit"
                      text={
                        isLoading ? (
                          <span className="loading loading-spinner loading-sm"></span>
                        ) : (
                          "Submit"
                        )
                      }
                      disabled={isLoading}
                      variant="filled"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <dialog id="my_modal_2" className="modal">
        <div className="modal-box px-[3rem] ">
          <h3 className="section-title">Instructions!</h3>
          <p className="section-text">
            <p className="mb-2">
              <b>ALL</b> sections of this application form <b>MUST</b> be
              completed in
              <b> FULL</b>.
              <br />
            </p>
            <p className="">
              Before you start the application please ensure you have the
              following information available. This is required for us to
              process Your application.
            </p>
            <br />
            <ul className=" list-disc mb-2">
              <li>
                A minimum of 5 years checkable history, with no gaps. This must
                cover all periods of employment, unemployment, education or
                self-employment. As a minimum you must include full names,
                addresses and telephone numbers.
              </li>
            </ul>
            <p>
              If Your application is successful, you will be required to provide
              the following documentation:
            </p>
            <br />
            <p>
              <b>Right to Work: </b>
            </p>
            <ul className=" list-disc mb-4 mt-2">
              <li>Valid UK Passport, or</li>
              <li>Valid EU Passport, or</li>
              <li>
                Original UK Birth Certificate (plus proof of Your National
                Insurance Number), or
              </li>
              <li>
                Valid Non-EU Passport with valid residence permit or biometric
                card
              </li>
            </ul>
            <p>
              <b>Proof of Address: (we will require two documents)</b>
            </p>
            <ul className="list-disc mt-2 mb-4">
              <li>
                Valid UK photo card driving licence – front and back (full or
                provisional)
              </li>
              <li>
                Bank or Building Society statements, dated within the last three
                months. You can use two statements if they are issued by a
                different bank
              </li>
              <li>
                Utility Bill e.g. gas, electric, water, broadband, dated within
                the last three months
              </li>
              <li>
                Letter from H.M. Revenue and Customs, Department for Work and
                Pensions or Local Authority, dated within the last three months
              </li>
              <li>Credit Card statement, dated within the last 3 months</li>
              <li>Council Tax statement, dated within the last 3 months</li>
              <li>
                P45 – printed copy only is acceptable and must be dated within
                the last 12 months
              </li>
              <li>
                P60 – printed copy is only acceptable and must be dated within
                the current tax year
              </li>
            </ul>
            <p>
              <b>Proof of National Insurance (one of the below) </b>
            </p>
            <ul className="list-disc mt-2">
              <li>P45 – printed copy only, or</li>
              <li>P60 printed copy only, or</li>
              <li>
                Letter from H.M. Revenue and Customs or department of Work and
                Pensions
              </li>
              <li>
                Payslip – printed copy showing previous employers company name
                (must be within 6 months)
              </li>
            </ul>
            <br />
            <p>
              The information obtained as part of the BS 7858 and any additional
              checks are retained for a period of 7 years after the end of
              employment.
            </p>
          </p>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </>
  );
}

export default ApplyNowForm;
