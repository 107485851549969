import React, { useEffect, useRef, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "../shared/Navbar";
import Footer from "../shared/Footer";
import ApplyNowForm from "../shared/ApplyNowForm";
import Contract from "../pages/Contract";

const Home = React.lazy(() => import("../pages/Home"));
const About = React.lazy(() => import("../pages/AboutUs"));
const Contact = React.lazy(() => import("../pages/ContactUs"));

function RouteConfig() {
  const pathName = useLocation();
  const [active, setActive] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathName]);

  return (
    <>
      <Navbar active={active} setActive={setActive} />
      <Routes>
        <Route path="/" exact element={<Home setActive={setActive} />} />
        <Route path="/about-us" exact element={<About />} />
        <Route path="/contact-us" exact element={<Contact />} />
        <Route path="/apply-now" element={<ApplyNowForm />} />
        <Route path="/contractors" element={<Contract />} />
      </Routes>
      <Footer />
    </>
  );
}

export default RouteConfig;
