import { Linkedin, Mail, MapPin, Phone } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import data from '../data.json';

function Footer() {
  const [footerLink, setFooterLinks] = useState([]);
  const { footerNavLinks, otherNavLinks } = data;

  const location = useLocation();

  useEffect(() => {
    if (location?.pathname == '/') {
      setFooterLinks(footerNavLinks);
    } else {
      setFooterLinks(otherNavLinks);
    }
  }, [location?.pathname]);

  return (
    <footer className='bg-white lg:h-[300px] pb-4 flex flex-col'>
      <div className='grid grid-cols-12 mb-4'>
        <div className='col-span-4 h-[3px] w-full bg-primary'></div>
        <div className='col-span-4 h-[3px] w-full bg-black'></div>
        <div className='col-span-4 h-[3px] w-full bg-primary'></div>
      </div>
      <div className='container mx-auto px-4 sm:px-0  flex flex-col justify-center h-full'>
        <div className='grid grid-cols-12 gap-4'>
          <div className='col-span-12 md:col-span-5 lg:col-span-6'>
            <div className='h-[90px] flex flex-col items-start justify-center'>
              <img src='./assets/images/logo.png' alt='logo' className='h-[55px] w-[150px]' />
            </div>

            <p className='section-text max-w-[80%] md:max-w-[90%] lg:max-w-[80%] my-4'>
              MH7 Services Group is a diversified holding company providing comprehensive business solutions across
              multiple sectors, including transportation, security, cleaning, and business consultancy.
            </p>
            <div className='flex items-center'>
              <div className='border-0 bg-primary w-[40px] h-[40px] rounded-full flex items-center justify-center me-2 group hover:border hover:border-primary hover:bg-white cursor-pointer'>
                <Linkedin className='w-[25px] h-[25px] text-white group-hover:text-primary' />
              </div>
            </div>
          </div>
          <div className='col-span-12 md:col-span-2 lg:col-span-3 flex flex-col'>
            <p className='text-2xl font-semibold text-primary mb-2 leading-snug tracking-wider'>Links</p>
            {location?.pathname == '/'
              ? footerLink?.map((data, index) =>
                  data?.type == 'external' ? (
                    <NavLink to={data?.link} className='footer-link my-2' key={index}>
                      {data?.name}
                    </NavLink>
                  ) : (
                    <a href={`#${data?.link}`} className='footer-link my-2' key={index}>
                      {data?.name}
                    </a>
                  )
                )
              : footerLink?.map((data, index) => (
                  <NavLink to={data?.link} className={`footer-link my-2`} key={index}>
                    {data?.name}
                  </NavLink>
                ))}

            <div className='flex flex-col'>
              <a className='footer-link my-2' onClick={() => window.open('https://mh7security.com/')}>
                MH7 Security Services
              </a>
              <a className='footer-link my-2' onClick={() => window.open('https://mh7crservices.com/')}>
                MH7 Cleaning Services
              </a>
              <a className='footer-link my-2' onClick={() => window.open('https://mh7transportation.com/')}>
                MH7 Transportation Services
              </a>
            </div>
          </div>
          <div className='col-span-12 md:col-span-5 lg:col-span-3 flex flex-col'>
            <p className='text-2xl font-semibold text-primary mb-2 leading-snug tracking-wider'>Meet Us</p>

            <div className='flex items-center my-3'>
              <div className='border border-primary rounded-full p-2 me-2'>
                <MapPin className='w-5 h-5 text-primary' />
              </div>
              <p className='footer-link'>
                109, Watling Court, Orbital Plaza,
                <br />
                Birmingham, West Midlands WS11 0DQ United Kingdom
              </p>
            </div>
            <div className='flex items-center my-3'>
              <div className='border border-primary rounded-full p-2 me-2'>
                <Phone className='w-5 h-5 text-primary' />
              </div>
              <p className='footer-link'>
                <a href='tel:+44 (0) 204 5721774'>+44 (0) 204 5721774</a>
              </p>
            </div>
            <div className='flex items-center my-3'>
              <div className='border border-primary rounded-full p-2 me-2'>
                <Mail className='w-5 h-5 text-primary' />
              </div>
              <p className='footer-link'>
                <a href='mailto:info@mh7services.group'>info@mh7services.group</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
