import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ChevronDown, ChevronUp } from "lucide-react";
import Button from "./Button";
import data from "../data.json";
import Loading from "../shared/Loading";
export const scrollToFRef = (ref) => {
  ref.current?.scrollIntoView({ behavior: "smooth" });
};

const Navbar = ({ setActive, active }) => {
  const [navLinks, setNavlinks] = useState([]);
  const { homeNavLinks, otherNavLinks } = data;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenC, setIsDropdownOpenC] = useState(false);
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (location?.pathname == "/") {
      setNavlinks(homeNavLinks);
    } else {
      setNavlinks(otherNavLinks);
    }
  }, [location?.pathname]);

  const navigate = useNavigate();
  const handleOpenForm = (type) => {
    setLoading(true);
    const navigateToPage = () => {
      if (type === "TRANSPORTATION") {
        navigate("/apply-now", { state: { data: "transportation" } });
      } else if (type === "SECURITY") {
        navigate("/apply-now", { state: { data: "security" } });
      } else if (type === "CLEANING") {
        navigate("/apply-now", { state: { data: "cleaning" } });
      }
      setLoading(false);
      setIsDropdownOpen(false);
    };
    if (location.pathname === "/apply-now") {
      setTimeout(navigateToPage, 500);
    } else {
      navigateToPage();
    }
  };
  const handleOpenContractForm = (type) => {
    setLoading(true);
    const navigateToPage = () => {
      if (type === "TRANSPORTATION") {
        navigate("/contractors", { state: { data: "transportation" } });
      } else if (type === "SECURITY") {
        navigate("/contractors", { state: { data: "security" } });
      } else if (type === "CLEANING") {
        navigate("/contractors", { state: { data: "cleaning" } });
      }
      setLoading(false);
      setIsDropdownOpenC(false);
    };
    if (location.pathname === "/contractors") {
      setTimeout(navigateToPage, 500);
    } else {
      navigateToPage();
    }
  };
  return (
    <>
      <div className="bg-white shadow w-full z-[2000] h-[90px] fixed top-0 flex items-center ">
        <div className="navbar bg-transparent container mx-auto">
          <div className="navbar-start">
            <div className="dropdown">
              <div
                tabIndex={0}
                role="button"
                className="btn btn-ghost lg:hidden"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h8m-8 6h16"
                  />
                </svg>
              </div>
              <ul
                tabIndex={0}
                className="menu menu-sm dropdown-content bg-white  rounded-box mt-3 w-60 p-2 shadow z-[99]"
              >
                {location?.pathname == "/"
                  ? navLinks?.map((data, index) =>
                      data?.type == "external" ? (
                        <NavLink
                          onClick={() => {
                            setActive(data?.link);
                          }}
                          to={data?.link}
                          className={`nav-link my-3 ${
                            active === data?.link ? "active-nav" : ""
                          }`}
                          key={index}
                        >
                          {data?.name}
                        </NavLink>
                      ) : (
                        <div className="relative group">
                          <div className="flex items-center">
                            <a
                              onClick={() => {
                                setActive(data?.link);
                              }}
                              className={`nav-link my-3 relative ${
                                active === data?.link ? "active-nav" : ""
                              }`}
                              href={`#${data?.link}`}
                              key={index}
                            >
                              {data?.name}
                            </a>
                            {data?.childs && (
                              <>
                                <ChevronDown className="group-hover:block hidden transition-all" />
                                <ChevronUp className=" group-hover:hidden block transition-all" />
                              </>
                            )}
                          </div>
                          {data?.childs && (
                            <div className="absolute left-0 top-[60%] bg-white hidden  group-hover:block z-[99]">
                              <div className="bg-white py-2 rounded-md shadow-xl mt-1">
                                {data?.childs?.map((child, index) => (
                                  <p
                                    onClick={() => window.open(child?.link)}
                                    to={child?.link}
                                    className={`nav-link my-1 block !min-w-[150px] whitespace-nowrap !text-[14px]`}
                                    key={index}
                                  >
                                    {child?.name}
                                  </p>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      )
                    )
                  : navLinks?.map((data, index) => (
                      <NavLink
                        to={data?.link}
                        className={`nav-link my-3`}
                        key={index}
                      >
                        {data?.name}
                      </NavLink>
                    ))}
                <div className="flex flex-col md:hidden">
                  <div className="relative">
                    <Button
                      text={"Apply Now"}
                      variant={"filled"}
                      onClick={() => {
                        setIsDropdownOpen(!isDropdownOpen);
                        setIsDropdownOpenC(false);
                      }}
                      className="!w-full"
                    />
                    {isDropdownOpen && (
                      <div className="absolute left-0 bottom-[-140px] mt-2 p-2 bg-white border border-gray-300 shadow-md rounded-md">
                        <ul className="space-y-1 text-black font-poppins">
                          <li
                            className="hover:bg-gray-100 p-2 cursor-pointer"
                            onClick={() => handleOpenForm("TRANSPORTATION")}
                          >
                            MH7 TRANSPORTATION
                          </li>
                          <li
                            className="hover:bg-gray-100 p-2 cursor-pointer"
                            onClick={() => handleOpenForm("CLEANING")}
                          >
                            MH7 CLEANING
                          </li>
                          <li
                            className="hover:bg-gray-100 p-2 cursor-pointer"
                            onClick={() => handleOpenForm("SECURITY")}
                          >
                            MH7 SECURITY
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className=" relative">
                    <Button
                      text={"Contractors"}
                      className="mt-3"
                      variant={"outlined"}
                      onClick={() => {
                        setIsDropdownOpenC(!isDropdownOpenC);
                        setIsDropdownOpen(false);
                      }}
                    />
                    {isDropdownOpenC && (
                      <div className="absolute left-0 bottom-[-140px] mt-2 p-2 bg-white border border-gray-300 shadow-md rounded-md">
                        <ul className="space-y-1 text-black font-poppins">
                          <li
                            className="hover:bg-gray-100 p-2 cursor-pointer"
                            onClick={() =>
                              handleOpenContractForm("TRANSPORTATION")
                            }
                          >
                            MH7 TRANSPORTATION
                          </li>
                          <li
                            className="hover:bg-gray-100 p-2 cursor-pointer"
                            onClick={() => handleOpenContractForm("CLEANING")}
                          >
                            MH7 CLEANING
                          </li>
                          <li
                            className="hover:bg-gray-100 p-2 cursor-pointer"
                            onClick={() => handleOpenContractForm("SECURITY")}
                          >
                            MH7 SECURITY
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </ul>
            </div>
            <NavLink
              to="/"
              className="h-[90px] flex flex-col items-center justify-center"
            >
              <img
                src="./assets/images/logo.png"
                alt="logo"
                className="h-[55px] w-[150px]"
              />
            </NavLink>
          </div>
          <div className="navbar-center hidden lg:flex">
            <ul className="menu menu-horizontal px-1">
              {location?.pathname == "/"
                ? navLinks?.map((data, index) =>
                    data?.type == "external" ? (
                      <NavLink
                        onClick={() => {
                          setActive(data?.link);
                        }}
                        to={data?.link}
                        className={`nav-link my-3 ${
                          active === data?.link ? "active-nav" : ""
                        }`}
                        key={index}
                      >
                        {data?.name}
                      </NavLink>
                    ) : (
                      <div className="relative group">
                        <div className="flex items-center">
                          <a
                            onClick={() => {
                              setActive(data?.link);
                            }}
                            className={`nav-link my-3 relative ${
                              active === data?.link ? "active-nav" : ""
                            }`}
                            href={`#${data?.link}`}
                            key={index}
                          >
                            {data?.name}
                          </a>
                          {data?.childs && (
                            <>
                              <ChevronDown className="group-hover:block hidden transition-all" />
                              <ChevronUp className=" group-hover:hidden block transition-all" />
                            </>
                          )}
                        </div>
                        {data?.childs && (
                          <div className="absolute left-0 top-full hidden group-hover:block">
                            <div className="bg-white py-2 rounded-md shadow-lg mt-4">
                              {data?.childs?.map((child, index) => (
                                <p
                                  onClick={() => window.open(child?.link)}
                                  className={`nav-link my-2 block !min-w-[150px] whitespace-nowrap !mx-0`}
                                  key={index}
                                >
                                  {child?.name}
                                </p>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  )
                : navLinks?.map((data, index) => (
                    <NavLink
                      to={data?.link}
                      className={`nav-link my-3`}
                      key={index}
                    >
                      {data?.name}
                    </NavLink>
                  ))}
            </ul>
          </div>
          <div className="navbar-end hidden md:flex">
            <div className="relative ">
              <Button
                text={"Apply Now"}
                variant={"filled"}
                onClick={() => {
                  setIsDropdownOpen(!isDropdownOpen);
                  setIsDropdownOpenC(false);
                }}
              />
              {isDropdownOpen && (
                <div className="absolute left-0 bottom-[-150px] mt-2 p-2 bg-white border border-gray-300 shadow-md rounded-md">
                  <ul className="space-y-1 min-w-[200px] text-black font-poppins">
                    <li
                      className="hover:bg-gray-100 p-2  cursor-pointer"
                      onClick={() => handleOpenForm("TRANSPORTATION")}
                    >
                      MH7 TRANSPORTATION
                    </li>
                    <li
                      className="hover:bg-gray-100 p-2 cursor-pointer"
                      onClick={() => handleOpenForm("CLEANING")}
                    >
                      MH7 CLEANING
                    </li>
                    <li
                      className="hover:bg-gray-100 p-2 cursor-pointer"
                      onClick={() => handleOpenForm("SECURITY")}
                    >
                      MH7 SECURITY
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className="relative">
              <Button
                text={"Contractors"}
                className="ms-3"
                variant={"outlined"}
                onClick={() => {
                  setIsDropdownOpenC(!isDropdownOpenC);
                  setIsDropdownOpen(false);
                }}
              />
              {isDropdownOpenC && (
                <div className="absolute left-[-35px] bottom-[-150px] mt-2 p-2 bg-white border border-gray-300 shadow-md rounded-md">
                  <ul className="space-y-1 min-w-[200px] text-black font-poppins">
                    <li
                      className="hover:bg-gray-100 p-2  cursor-pointer"
                      onClick={() => handleOpenContractForm("TRANSPORTATION")}
                    >
                      MH7 TRANSPORTATION
                    </li>
                    <li
                      className="hover:bg-gray-100 p-2 cursor-pointer"
                      onClick={() => handleOpenContractForm("CLEANING")}
                    >
                      MH7 CLEANING
                    </li>
                    <li
                      className="hover:bg-gray-100 p-2 cursor-pointer"
                      onClick={() => handleOpenContractForm("SECURITY")}
                    >
                      MH7 SECURITY
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {loading && <Loading />}
    </>
  );
};

export default Navbar;
